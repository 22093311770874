<template>
  <div>
    <p>
      <strong>Government Personnel Benefits Plan</strong> (GPBP) is a unique & competitive resource for Govt. employees to review needed benefits, select what they want, and apply & pay
      for them online. GPBP was designed to fill a gap in coverages and services for Federal, State, City, County, Municipal and Postal employees.
      The flagship benefit of GPBP is the Group Term Life to age 90, which is Guarantee Issue of 1 X annual income or $150,000 whatever amount is less
      and this benefit is convertible to age 70.
    </p>
  </div>
</template>

<script>
export default {
  name: "GbbpBannerText"
}
</script>

<style lang="scss">
</style>