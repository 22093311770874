<template>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-if="this.$router.name=='error'">
    <error-page></error-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else-if="this.$router.name=='GroupHomeOption'">
    <group-home-option />
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else-if="this.$router.name=='decline-reminder-email'">
    <decline-email />
  </div>
  <div v-else-if="this.$route.params.landing === 'access-page'">
    <access-page></access-page>
  </div>
  <div v-else-if="this.$route.params.landing === 'access-page'">
    <access-page></access-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else>
<!--    <ben-header-area v-if="include_extra"></ben-header-area>-->
<!--    <header-area v-if="include_old"></header-area>-->
    <router-view></router-view>
<!--    <open-page v-if="landing_type == 'user' && isOpenEnrollment"></open-page>
    <open-page  v-else-if="locationHostName == envHostName && isOpenEnrollment"></open-page>
    <agent-open-page v-else-if="landing_type == 'agent' && isOpenEnrollment && locationHostName !== envHostName"></agent-open-page>
    <group-open-page v-else-if="landing_type == 'group' && isOpenEnrollment && locationHostName !== envHostName"></group-open-page>-->
    <landing-page v-if="landing_type == 'user' && isOpenEnrollment"/>
    <landing-page v-else-if="locationHostName == envHostName && isOpenEnrollment" />
<!--    <support-area v-if="include_extra"></support-area>-->
<!--    <ben-footer-area v-if="include_extra"></ben-footer-area>-->
<!--    <footer-area v-if="include_old"></footer-area>-->
  </div>
</template>

<script>
  // import Header from '../components/Header'
  // import BenAdvanceHeader from '../components/BenHeader'
  // import Footer from '../components/Footer'
  import ErrorPage from '../components/404'
  import LandingPage from "../components/new_home/LandingPage.vue";

  // import BenFooter from '../components/BenFooter'
  // import Support from "../components/Support"
/*  import OpenPage from "../views/OpenPage"
  import AgentOpenHome from "../views/AgentOpenHome";
  import GroupOpenHome from "../views/GroupOpenHome";*/
  import axios from "axios";
  import Api from "../includes/Api";
  import DeclineEmail from '../components/DeclineEmail.vue'
  import GroupHomeOption from "./GroupHomeOption";
  export default {
    name: "Home",
    components: {
      // 'ben-header-area': BenAdvanceHeader,
      // 'header-area': Header,
      // 'footer-area': Footer,
      // 'ben-footer-area': BenFooter,
      // 'support-area' : Support,
      //'open-page' : OpenPage,
      //'agent-open-page' : AgentOpenHome,
      //'group-open-page' : GroupOpenHome,
      'landing-page' : LandingPage,
      'error-page' : ErrorPage,
      'decline-email': DeclineEmail,
      'group-home-option': GroupHomeOption
    },
    data: function() {
      return {
        landing_type : '',
        include_extra: true,
        include_old: false,
        portalId: null,
        locationHostName: location.host,
        envHostName: process.env.VUE_APP_SITE_HOST_NAME,
        eprocess: '',
        openType: '',
        landingName: ''
      }
    },
    computed: {
      isOpenEnrollment() {
        if(this.$route.name == "main") {
            return true;
        } else {
          return false
        }
      },
      isOpenCommonEnrollment () {
        if(this.$route.name == "main") {
          if (this.$route.params.landing) {
            return false;
          } else {
            return true;
          }
        } else {
          return false
        }
      },
      isGroupViewPlans () {
        return this.$store.getters.isGroupViewPlans
      },
    },
    mounted() {
      console.log('parent called');
      let app = this;
      app.landing_type = window.localStorage.getItem('open_type');
      app.include_extra = app.$route.name !== 'error' && app.$route.name !== 'continue-enrollment';
      app.include_old = app.$route.name === 'continue-enrollment';
      app.fromSite = window.localStorage.getItem('fromSite');
      app.eprocess = window.localStorage.getItem('eprocess');
      app.openType = window.localStorage.getItem('open_type');
      app.landingName = window.localStorage.getItem('landingName');
      app.fetchGoEnrollInfo();
      app.checkForElevateTowellnessLink();
      app.routeToViewPlans();
    },
    methods: {
      checkForElevateTowellnessLink () {
        let app = this;
        if (app.fromSite === 'elevate' && app.landing_type === 'group') {
            axios.get(process.env.VUE_APP_API_BASE+'/get-elevate-info')
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.portalId = response.data.data.portal_id
                  window.localStorage.setItem('portal_id', app.portalId);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
             this.$router.push('/');
        }
      },
      fetchGoEnrollInfo () {
        let app = this
        let siteHostNameLocal = process.env.VUE_APP_SITE_HOST_NAME_LOCAL
        if ((location.host == process.env.VUE_APP_SITE_HOST_NAME && app.landing_type == 'agent' && app.$route.params.landing) || (location.host == process.env.VUE_APP_SITE_HOST_NAME  && app.landing_type == 'group' && app.$route.params.landing)) {
          Api.get('/get-domainSite-infromation?site_domain=' + window.btoa(unescape(encodeURIComponent( siteHostNameLocal )))).then((response)=> {
              if(response.data.statusCode == 200) {
                 if (app.landing_type !== 'group') {
                   window.localStorage.setItem('group_id', response.data.data.gid);
                 }
                  window.localStorage.setItem('portal_id', response.data.data.gid);
                  window.localStorage.setItem('enroll_type', 'new-group-member');
                  app.$bus.$emit('getMenuList');
                  app.$bus.$emit('getGroupDetail');
                  app.$bus.$emit('getRecommendedList')
                  app.$bus.$emit('renderZipCode')
                  app.$bus.$emit('renderPlanList')
                  // app.$router.push('/');
              }
            }).catch(() => {
              app.$router.push('/404');
            })
        }
      },
      routeToViewPlans () {
        let app = this
        if (app.isGroupViewPlans) {
          app.$router.push('/plans')
          app.$store.dispatch('isGroupViewPlans', false)
        }
      }
    },
    listener: {
      'updateLandingType'() {
        let app = this
        app.landing_type = window.localStorage.getItem('open_type');
      }
    }
  }
</script>
